<template>
    <section>
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> </span>
                <!-- span class="head__sub-title"> &nbsp;/&nbsp;Sensor setting</span -->
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <nav class="section__navigation plr__12 pt__12">
                <div class="segment-control mb__12">
                    <ul class="segment-control__list">
                    </ul>
                </div>
            </nav>

            <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
                {{ $t('Chat') }}
            </section>

        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "UnitChat",
        props: [
            'unitId',
        ],
        components: {
            //'':    () => import('./'),
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                this.$emit('close-dialog')
            }
        },
        created() {
        },
        updated() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>